class WeakNetworkDetector {
  constructor() {
    setTimeout(() => {
      this.getWeakNetwork.call(this)
    }, this.timeGap)
    // window.addEventListener('offline', () => {
    //   this.blocked = true
    //   window.$tool.block()
    // })
  }
  blocked = false // 是否阻断

  timer = 0 // 丢包次数
  timesLimit = 3 // 丢包次数阈值
  timeGap = 10000 // 丢包监测时间间隔
  timeThreshold = 5000 // 丢包监测时间阈值

  getWeakNetwork = async () => {
    const date = Date.now()
    try {
      const DOMAIN_MS = $ZConfig.getServerUrl()
      await fetch(DOMAIN_MS + '/health-check.json', {
        method: 'HEAD',
        mode: 'no-cors',
        credentials: 'omit'
      })
      if (Date.now() - date > this.timeThreshold) {
        this.timer++
      } else {
        this.timer = 0
      }
    } catch (e) {
      this.timer++
    }
    if (this.timer >= this.timesLimit) {
      window.flutter_inappwebview.callHandler('toast', JSON.stringify({ msg: '网络环境不佳', duration: 2000 }))
      console.log('weakNetworkDetector', '弱网')
      this.timer = 0
    }
    setTimeout(() => {
      if (!this.blocked) {
        this.getWeakNetwork()
      }
    }, this.timeGap)
  }
}

export default WeakNetworkDetector
