if (localStorage.getItem('debug-eruda') == 'true') {
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.crossorigin = 'anonymous'
  script.src = 'https://static.service.z-trip.cn/resources/lib/eruda.js'
  document.head.appendChild(script)
  script.async = true
  const getCookie = (e) => {
    const cookie = document.cookie.split('; ')
    for (let i = 0; i < cookie.length; i++) {
      const [key, value] = cookie[i].split('=')
      if (key === e) return value
    }
  }
  script.onload = () => {
    const a = window.eruda.init()
    window.eruda.remove('sources')
    console.log('eruda a: ', a)
    window.eruda.useDebugBar = useDebugBar
    queue.forEach((item) => {
      window.eruda.useDebugBar(item.title, item.data)
    })
    queue.length = 0
    const info = window.eruda.get('info')
    info.remove('Backers')
    info.remove('About')
    info.remove('Device')
    info.remove('System')
    info.add('sessionId', () => sessionStorage.getItem('WEB_SESSION_ID'))
    info.add('userId', () => localStorage.getItem('userId'))
    if (getCookie('zaitu-ver')) {
      info.add('zaitu-ver', () => {
        return [getCookie('zaitu-ver')]
      })
    }
    if (getCookie('zaitu-ver-uat')) {
      info.add('zaitu-ver-uat', () => {
        return getCookie('zaitu-ver-uat')
      })
    }
    const snippet = window.eruda.get('snippets')
    snippet.clear()
    snippet.add(
      '插件调试',
      () => {
        window.eruda.useDebugBar('插件调试', {
          from: 'm/platform/plugin/common',
          name: 'currency'
        })
        window.eruda.show('插件调试')
      },
      '访问插件页面'
    )
    snippet.add(
      '远程调试',
      () => {
        const confirm = window.confirm('注意,远程调试只能在内网访问')
        if (confirm) {
          localStorage.setItem('debug-pagespy', localStorage.getItem('debug-pagespy') === 'true' ? 'false' : 'true')
          location.reload()
        } else {
          window.alert('取消')
        }
      },
      localStorage.getItem('debug-pagespy') == 'true' ? '🟢 已开启' : '🔴 已关闭'
    )
    snippet.add(
      '刷新',
      () => {
        location.reload()
      },
      '🔄 刷新页面'
    )
  }
}
if (localStorage.getItem('debug-pagespy') == 'true') {
  var script2 = document.createElement('script')
  script2.type = 'text/javascript'
  script2.crossorigin = 'anonymous'
  script2.src = 'https://front-debug.z-trip.cn/page-spy/index.min.js'
  document.head.appendChild(script2)
  script2.async = true
  script2.onload = () => {
    window.$pageSpy = new window.PageSpy()
  }
  script2.onerror = () => {
    localStorage.removeItem('debug-pagespy')
    alert('远程调试启动失败，请检查网络环境')
    location.reload()
  }
}
let queue = []
export const useDebugBar = (
  title = '',
  data = {
    from: '',
    name: ''
  }
) => {
  if (window.eruda) {
    window.eruda.add({
      name: title,
      init($el) {
        const from = data.from
        const name = data.name
        window.__qiankun__.loadMicroApp({
          name: `${from}__${name}`,
          entry: `${location.origin}/${from}/${name}.html?_t=${Date.now()}`,
          container: $el[0]
        })
        this._$el = $el
      },
      show() {
        this._$el.show()
      },
      hide() {
        this._$el.hide()
      },
      destroy() {}
    })
    queue.push({
      title,
      data
    })
  } else {
    queue.push({
      title,
      data
    })
  }
}
