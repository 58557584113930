import { getZtCoreVersion } from './libs'
import { trace } from './trace'
const formatNav = (data) => {
  trace.tag('FLUTTER_FORMAT_NAV', { data })
  console.log('flutter_nav', data)
  /**
      dlat string 终点纬度 必填
      dlon string 终点经度 必填
      dname string 终点名称
      slat stirng 起点纬度如果起点的坐标，默认为当前位置
      slon string 起点经度
      sname string 起点名称
    */
  let params = {}
  if (data.to && data.to.lat && data.to.lng) {
    params.dlat = data.to.lat
    params.dlon = data.to.lng
    params.dname = data.to.name || '终点'
  } else {
    return
  }
  if (data.from && data.from.lat && data.from.lng) {
    params.slat = data.from.lat
    params.slon = data.from.lng
    params.sname = data.from.name || '起点'
  }

  if (data.mapType) {
    params.mapType = data.mapType
  }
  trace.tag('FLUTTER_FORMAT_NAV_HANDLE', { data: params })
  console.log('formatNav', params)
  return params
}
class Flutter {
  appInfo = {}
  constructor() {}
  support = {
    openNav: getZtCoreVersion() >= 3,
    getNavList: getZtCoreVersion() >= 5,
    selectNav: getZtCoreVersion() >= 5
  }
  isLogin() {
    let token = localStorage.getItem('token')
    if (token) {
      return true
    } else {
      return false
    }
  }
  /**
   * openNav 基于国测局提供导航功能
   * @param {Object} data
   * @param {Object} data.to 终点
   * @param {number} data.to.lat 终点纬度
   * @param {number} data.to.lng 终点经度
   * @param {string} data.to.name 终点名称
   * @param {Object} [data.from] 起点
   * @param {number} data.from.lat 起点纬度
   * @param {number} data.from.lng 起点经度
   * @param {string} data.from.name 起点名称
   */
  async openNav(data) {
    const params = formatNav(data)
    return await window.flutter_inappwebview.callHandler('selectMapNavigation', JSON.stringify(params))
  }
  /**
   * 获取导航列表
   * @returns {
   *  Array<{
   *    isInstall: boolean,
   *    mapType: string,
   *    name: string
   *  }>
   * }
   */
  async getNavList() {
    const data = await window.flutter_inappwebview.callHandler('getMapNavigationList', JSON.stringify({}))
    if (data.code == 0) {
      return data.data
    } else {
      throw data
    }
  }
  /**
   * 选择导航 "苹果" "高德" "百度"
   * @param {Object} data
   * @param {string} data.mapType 地图类型 baidu/amap/apple
   * @param {Object} data.to 终点
   * @param {number} data.to.lat 终点纬度
   * @param {number} data.to.lng 终点经度
   * @param {string} data.to.name 终点名称
   * @param {Object} [data.from] 起点
   * @param {number} data.from.lat 起点纬度
   * @param {number} data.from.lng 起点经度
   * @param {string} data.from.name 起点名称
   */
  async selectNav(data) {
    const params = formatNav(data)
    console.log('selectNav', params)
    return await window.flutter_inappwebview.callHandler('selectMapNavigation', JSON.stringify(params))
  }

  popPage() {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('popPage', JSON.stringify({}))
      console.log('popPage')
      console.log(ret)
    }
  }

  replacePage(data) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler(
        'replacePage',
        JSON.stringify({ pageName: data.pageName || '/home' })
      )
      console.log('replacePage')
      console.log(ret)
    }
  }

  // 这里flutter启动时会掉injectAppInfos方法,传入参数;
  getAppInfo() {
    return this.appInfo
  }

  getLocation(callbackFnNameOnWindow = 'flutterLocaionCb') {
    // window.flutter_inappwebview && window.flutter_inappwebview.callHandler('startLocation',JSON.stringify({callFnName:callbackFnNameOnWindow}))
    window.flutter_inappwebview &&
      window.flutter_inappwebview.callHandler('getLocationInfo', JSON.stringify({ callFnName: callbackFnNameOnWindow }))
  }

  logOut(isJump = true) {
    window.flutter_inappwebview &&
      window.flutter_inappwebview.callHandler('logoutCallBack', JSON.stringify({ isJump: isJump }))
  }
  closeWidget() {
    window.flutter_inappwebview && window.flutter_inappwebview.callHandler('closeWidget', JSON.stringify({}))
  }
  // 触觉 3dtouch 震动
  haptic(type = 'light') {
    //type:  heavy light medium 3种类型
    window.flutter_inappwebview && window.flutter_inappwebview.callHandler('haptic', JSON.stringify({ type }))
  }

  openDebug() {
    window.flutter_inappwebview && window.flutter_inappwebview.callHandler('openDebug', JSON.stringify({}))
  }

  setEnv(env) {
    window.flutter_inappwebview && window.flutter_inappwebview.callHandler('setEnv', JSON.stringify({ env }))
  }

  calJsMethod(code) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('calJsMethod', JSON.stringify({ code: code }))
      console.log('calJsMethodbc')
      console.log(typeof ret)
      console.log(ret)
      ret.then((data) => {
        console.log(data)
      })
    }
  }
  async getClipboard() {
    if (window.flutter_inappwebview) {
      const ret = await window.flutter_inappwebview.callHandler('getClipboard', {})
      console.log('getClipboard')
      console.log(ret)
      return ret
    }
    return null
  }
  async setClipboard(str) {
    if (window.flutter_inappwebview) {
      const ret = await window.flutter_inappwebview.callHandler('setClipboard', JSON.stringify({ text: str }))
      console.log('setClipboard', str)
      console.log(ret)
      return ret
    }
    return null
  }
  launchUrl(data) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('launchUrl', JSON.stringify(data))
      console.log('launchUrl', data)
      console.log(ret)
    }
  }
  async setNavBar(data) {
    if (window.flutter_inappwebview) {
      const ret = await window.flutter_inappwebview.callHandler('setNavBar', JSON.stringify(data))
      console.log('setNavBar', data)
      console.log(ret)
    }
  }
  freshWebview(data) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('freshWebview', JSON.stringify(data))
      console.log('freshWebview', data)
      console.log(ret)
    }
  }
  sendMsgToWebView(data) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('sendMsgToWebview', JSON.stringify(data))
      console.log('sendMsgToWebView', data)
      console.log(ret)
    }
  }
  goWebview(data) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('goWebview', JSON.stringify(data))
      console.log('goWebview', data)
      console.log(ret)
    }
  }
  goNavigator(data) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('goNavigator', JSON.stringify(data))
      console.log('goNavigator', data)
      console.log(ret)
    }
  }
  goMyWeb(data) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('goMyWeb', JSON.stringify(data))
      console.log('goMyWeb', data)
      console.log(ret)
    }
  }
  toggleNav(data) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('toggleNav', JSON.stringify(data))
      console.log('toggleNav', data)
      console.log(ret)
    }
  }
  onSendMsg(dataStr) {
    const data = JSON.parse(dataStr)
    console.log('==监听到SendMsg', data)
    if (data.type == 'RELOAD') {
      setTimeout(() => {
        // window.location.reload()
        alert(dataStr)
      }, data.time)
    }
  }
  sendPush(data) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('sendLocalNotification', JSON.stringify(data))
      console.log('sendLocalNotification', data)
      console.log(ret)
    }
  }
  changeBgColor(data) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('changeBgColor', JSON.stringify(data))
      console.log('changeBgColor', data)
      console.log(ret)
    }
  }
  globalPostMSG(data) {
    if (window.flutter_inappwebview) {
      const ret = window.flutter_inappwebview.callHandler('globalPostMSG', JSON.stringify(data))
      console.log('globalPostMSG', data)
      console.log(ret)
    }
  }
  // 订阅多个webview的的事件;方便处理跨iframe事件;
  subGlobalMsg(data) {
    console.log('subGlobalMsg', data)
    const dataJson = JSON.parse(data)
    alert(dataJson.type)
  }
  async injectJs(data) {
    if (window.flutter_inappwebview) {
      const ret = await window.flutter_inappwebview.callHandler('injectJs', JSON.stringify(data))
      console.log('injectJs', data)
      console.log(ret)
    }
  }
  async fireClearAppCache(data) {
    if (window.flutter_inappwebview) {
      const ret = await window.flutter_inappwebview.callHandler('clearAppCache', JSON.stringify(data))
      console.log('clearAppCache')
      console.log(ret)
    }
  }
  clearAppCache(data) {
    console.log('clearAppCache', data, location.href)
  }
  async refreshPage() {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('refreshPage', JSON.stringify({}))
    }
  }
  reloadCurPage() {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('reloadCurPage', JSON.stringify({}))
    }
  }
  refreshAppToken(data) {
    if (window.flutter_inappwebview) {
      // token =='' refreshToken =='' 则会remove缓存,如果想删除app中的token,可以传入空
      window.flutter_inappwebview.callHandler(
        'refreshAppToken',
        JSON.stringify({
          token: data.jwt,
          refreshToken: data.refreshToken
        })
      )
    }
  }
  /**
   * 网络请求异常跳转至兜底异常页
   */
  block(href) {
    window.flutter_inappwebview.callHandler('openNetWorkError', JSON.stringify({ url: href, header: {}, message: '' }))
  }

  /**
   * 获取系统权限
   * @param {Array<string>} [
      'audio',//语音
      'camera',//相机
      'storage',//存储
      'photos',//相册
      'location',//定位
      'bluetooth'//蓝牙
    ]
   *
   * @returns 
   */

  async requestPermission(list) {
    const res = await window.flutter_inappwebview.callHandler('requestPermission', JSON.stringify({ list }))
    return res
  }
  /** 语音相关 */
  audio = {
    // 获取录音权限
    async getPermission({ prompt } = { prompt: true }) {
      if (getZtCoreVersion() == 0) {
        throw {
          code: -2,
          message: '当前版本不支持语音录制'
        }
      } else {
        const res = await window.flutter_inappwebview.callHandler('getAudioPermission', JSON.stringify({ prompt }))
        return res
        // if (res.code == 0) {
        //   return res
        // } else {
        //   throw res
        // }
      }
    },
    /**
     * 录音启动
     * @returns
     */
    async start(params) {
      if (getZtCoreVersion() == 0) {
        throw {
          code: -2,
          message: '当前版本不支持语音录制'
        }
      }
      let d = {
        fileName: Date.now() + '--',
        sampleRate: 16000,
        numChannels: 1,
        duration: 60,
        audioEncoder: 'wav'
      }
      params && Object.assign(d, params)
      const res = await window.flutter_inappwebview.callHandler('startRecordAudio', JSON.stringify(d))
      if (res.code == 0) {
        return res
      } else {
        throw res
      }
    },
    async stop() {
      if (getZtCoreVersion() == 0) {
        throw {
          code: -1,
          message: '当前版本不支持语音录制'
        }
      }
      function base64ToFile(base64Data, filename) {
        // 将base64的数据部分提取出来
        const parts = base64Data.split(';base64,')
        const contentType = parts[0].split(':')[1]
        const raw = window.atob(parts[1])

        // 将原始数据转换为Uint8Array
        const rawLength = raw.length
        const uInt8Array = new Uint8Array(rawLength)
        for (let i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i)
        }

        // 使用Blob对象创建File对象
        const blob = new Blob([uInt8Array], { type: contentType })
        blob.lastModifiedDate = new Date()
        blob.name = filename

        return new File([blob], filename, { type: contentType })
      }
      const res = await window.flutter_inappwebview.callHandler('stopRecordAudio')
      if (res.code == 0) {
        const base64Data = res.data.videos
        const filename = 'audio/wav' // 文件名
        const file = base64ToFile('data:audio/wav;base64,' + base64Data, filename)
        console.log(file)
        const link = window.URL.createObjectURL(file)
        console.log(link)
        res.data.link = link
        res.data.file = file
        return res
      } else {
        throw res
      }
    },
    async cancel() {
      const res = await window.flutter_inappwebview.callHandler('stopRecordAudio')
      return res
    }
  }
}

const isFlutter = window.flutter_inappwebview ? true : false
export default (function () {
  console.log('init flutter')
  if (!window.$flutter && isFlutter) {
    window.$flutter = new Flutter()
  }
})()
