import axios from 'axios'
import { trace } from './trace'
const ENV_KEY = process.env.NODE_ENV || 'development'
const LOG_TYPES = {
  console: 'console',
  api: 'api',
  error: 'error'
}
// const DONT_MLOG = localStorage.getItem('DONT_MLOG') === 'true' ? true : false
const DONT_MLOG = true
//设置一个检查类型的函数
function checkType(target) {
  return Object.prototype.toString.call(target).slice(8, -1)
}

function appendExtInfo(params) {
  let localUserInfo = localStorage.getItem('localUserInfo') || '{}'
  localUserInfo = JSON.parse(localUserInfo)
  let zt_verName =
    (window.zzzconfig && window.zzzconfig.getZatuVerName && window.zzzconfig.getZatuVerName()) || 'zaitu-ver'

  return Object.assign({}, params, {
    'zaitu-ver': (window.$tool && window.$tool.getCookie(zt_verName)) || '',
    userId: localUserInfo.userId,
    userMobile: localUserInfo.mobile,
    location: location.href,
    ua: navigator.userAgent.toLowerCase()
  })
}

var debounce = (fn, wait) => {
  let timer
  let timeStamp = 0
  let context, args
  let run = () => {
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, wait)
  }
  let clean = () => {
    clearTimeout(timer)
  }
  return function () {
    context = this
    args = arguments
    let now = new Date().getTime()
    if (now - timeStamp < wait) {
      clean() // clear running timer
      run() // reset new timer from current time
    } else {
      run() // last timer alreay executed, set a new timer
    }
    timeStamp = now
  }
}
const generateUUID = function () {
  var d = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}
//递归遍历对象/数组元素。实现深拷贝
function clone(target) {
  //初始化一个result，作为将来要输出的结果
  //我们先检查将要进行拷贝的数据类型是对象还是数组，来进行不同的初始化赋值
  let result
  let targetType = checkType(target)
  if (targetType === 'Object') {
    result = {}
  } else if (targetType === 'Array') {
    result = []
  } else {
    //如果不是对象或者数组就直接我们输出输入的值，因为只有对象或数组才存在深拷贝
    return target
  }
  for (let i in target) {
    let value = target[i]
    //使用for ... in 循环遍历其下标，通过target[i]拿到当前出去迭代中的值
    //再通过上面定义的类型检查方法判断每一项的类型
    //如果是对象或者数组，接着对该属性进行遍历。如果不是，就将该值赋给result的同一下标下。
    if (checkType(value) === 'Object' || checkType(value) === 'Array') {
      result[i] = clone(value)
    } else {
      result[i] = value
    }
  }
  //最后输出result，达到深拷贝的目的
  return result
}
const BASE_URL = 'https://mlog.z-trip.cn/'
// const BASE_URL = 'http://127.0.0.1:7001/'
const CONFIG_URL = BASE_URL + 'api/config'
const API_LOG = BASE_URL + 'api/up_log'
const MAX_BUFFER_SIZE = 30 // 每次上传最大数组数量,多了抛弃
class mconsole {
  constructor(options = { appProject: '' }) {
    if (DONT_MLOG) {
      console.log('closed mconcole....')
      return
    }
    console.info('mconsole init .... xxx', document.location)
    this.pageInfo = {
      location: document.location.href,
      state: {},
      uuid: 'page-' + generateUUID()
    }
    // console.info('event....popstate', this.pageInfo)
    window.addEventListener('popstate', (event) => {
      // console.info("location: " + + ", state: " + JSON.stringify(event.state));
      this.pageInfo = {
        location: document.location.href,
        state: event.state,
        uuid: 'page-' + generateUUID()
      }
      // console.info('event....popstate new', this.pageInfo)
    })
    // window.addEventListener('hashchange', function (event) {
    //     console.log('event....hashchange', event)
    // })
    // window.addEventListener('replaceState', function (event) {
    //     console.log('event....replaceState', event)
    // })
    // window.addEventListener('historychange', function (event) {
    //     console.log('event....historychange', event)
    // })
    this._oriConsole = window.console.log
    this._oriConsoleErr = window.console.error
    this.buffers = []
    this.lastUpTime = Date.now()
    this.$http = axios.create({
      baseURL: '',
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Credentials': 'false',
        'Access-Control-Allow-Origin': '*'
      },
      timeout: 1000 * 8 // 请求超时时间
    })
    this.config = {}
    console.info('mconsole constructor')
    this.mlog = this.mlog.bind(this)
    this._log = this._log.bind(this)
    this.merror = this.merror.bind(this)
    this._pushMsg = this._pushMsg.bind(this)
    this._bachPushFn = this._bachPushFn.bind(this)
    this.init = this.init.bind(this)
    this.getConfig = this.getConfig.bind(this)
    this._hasFilterWords = this._hasFilterWords.bind(this)
    this.apilog = this.apilog.bind(this)
    this.upError = this.upError.bind(this)
    this.startUploadByPage = this.startUploadByPage.bind(this)
    this.getMsgAppendGroup = this.getMsgAppendGroup.bind(this)
    this.matchString = this.matchString.bind(this)
    this.endUploadByPage = this.endUploadByPage.bind(this)
    this.debounceFn = debounce(this._bachPushFn, 5000)
    this.$http
      .get(CONFIG_URL, {
        params: { appProject: options.appProject, env: ENV_KEY }
      })
      .then(({ data }) => {
        // console.info('CONFIG_URLxxxxxxxxxxx', data)
        //  this.config = data || {}
        let oriConfig = data.data || {}
        try {
          if (oriConfig.nowords.trim() !== '') {
            oriConfig.nowords = oriConfig.nowords.split(',')
          } else {
            oriConfig.nowords = []
          }
          if (oriConfig.mobiles.trim() !== '') {
            oriConfig.mobiles = oriConfig.mobiles.split(',')
          } else {
            oriConfig.mobiles = []
          }
          if (oriConfig.ips.trim() !== '') {
            oriConfig.ips = oriConfig.ips.split(',')
          } else {
            oriConfig.ips = []
          }
          // this._log(oriConfig)
          console.info('CONFIG_URL222xxxxxxxxxxx', oriConfig)
          this.init(oriConfig)
        } catch (err) {
          console.info('ERROR', err)
        }
      })
      .catch((err) => {
        this._log('CONSOLE CONFIG_URL ERROR', err)
      })
  }
  init(options = { cache: false, sentry: false, nowords: [], ips: [], mobiles: [] }) {
    this.config = Object.assign({}, this.config, options)
    this.config.needUpload = false
    //通过用户id过滤,通过ip过滤
    // localStorage.setItem('userInfo',JSON.stringify({mobile:userInfo.mobile,userId:userInfo.userId}))
    let userInfo = localStorage.getItem('localUserInfo') || '{}'
    userInfo = JSON.parse(userInfo)
    if (!userInfo.ip && window.returnCitySN) {
      userInfo.ip = window.returnCitySN?.cip
    }
    if (this.config.cache === true && DONT_MLOG === false) {
      // 根据mobile或者ip匹配
      if (
        userInfo &&
        ((userInfo.mobile && this.config.mobiles.includes(userInfo.mobile)) ||
          this.config.ips.includes('debug-ip') || // 客户端开启ip
          (userInfo.ip && this.config.ips.includes(userInfo.ip)))
      ) {
        this.config.needUpload = true
        this._log('mconsole 记录日志', userInfo.mobile || '', userInfo.ip || '')
        this.config.getlog && (window.console.log = this.mlog)
        this.config.geterror && (window.console.error = this.merror)
      } else {
        this._log('mconsole 不需要记录日志', userInfo.mobile || '', userInfo.ip || '')
      }
    } else {
      this._log('mconsole not init', userInfo.mobile || '', userInfo.ip || '')
      if (this.config.cache) {
        this.destory()
      }
    }
  }
  getConfig() {
    return this.config
  }
  mlog(...args) {
    if (DONT_MLOG || !this.config.needUpload) {
      return
    }
    let _filterArgs = this._filterArgs(args)
    if (_filterArgs.length > 0) {
      this._pushMsg({
        desc: JSON.stringify(_filterArgs),
        level: 'log',
        type: LOG_TYPES.console
      })
    }
    this._oriConsole.apply(null, args)
  }
  apilog(res) {
    if (DONT_MLOG || !this.config.needUpload) {
      // console.info('not upload------------------')
      return
    }
    const config = (res && res.config) || {}
    const data = (res && res.data) || {}
    let params = Object.assign(
      {},
      {
        base_url: config.baseURL || '',
        // tt params:JSON.stringify(config.params)||{},
        // data:JSON.stringify(config.data)||'',
        url: config.url || '',
        method: config.method || '',
        request_body: JSON.stringify({
          base_url: config.baseURL || '',
          params: JSON.stringify(config.params) || {},
          data: JSON.stringify(config.data) || '',
          url: config.url || '',
          method: config.method || ''
        }),
        response_body: JSON.stringify(data),
        api_trace_id: 'api-' + generateUUID(),
        deviceId: (window.api && window.api.deviceId) || '',
        cookie: document.cookie
      }
    )
    try {
      this._pushMsg(
        Object.assign({}, params, {
          // desc: JSON.stringify(params),
          type: LOG_TYPES.api
        })
      )
    } catch (err) {
      console.error(err)
    }
  }
  merror(...args) {
    //this._log('error=======start')
    if (DONT_MLOG || !this.config.needUpload) {
      return
    }
    let _filterArgs = this._filterArgs(args)
    // 错误搜集,这里条件放宽,不知道会不会异常,所以try处理
    try {
      this._pushMsg({
        desc: JSON.stringify(_filterArgs),
        level: 'error',
        type: LOG_TYPES.console
      })
    } catch (err) {
      console.error(err)
    }
    this._oriConsoleErr.apply(null, args)
  }
  _hasFilterWords(desc = '') {
    let nowords = this.config.nowords
    for (let i = 0, len = nowords.length; i < len; i++) {
      if (desc.indexOf(nowords[i]) !== -1) {
        return true
      }
    }
    return false
  }
  _pushMsg(param) {
    // 这里需要过滤掉一些log非必需的输出,
    if (param.type === 'console' && this._hasFilterWords(param.desc)) {
      this._log('有关键字,过滤掉', param.desc)
      return
    }
    let userInfo = ''
    if (!window['userInfoCache']) {
      userInfo = JSON.parse(localStorage.getItem('localUserInfo') || '{}')
      window['userInfoCache'] = userInfo
    }
    // let userInfo = localStorage.getItem('localUserInfo') || '{}'

    let data = Object.assign({}, param, {
      deviceId: (window.api && window.api.deviceId) || '',
      cookie: document.cookie,
      ip: window.returnCitySN?.cip || '',
      user_id: localStorage.getItem('userId') || '',
      mobile: window['userInfoCache'].mobile || '',
      path: location.href,
      orgin: location.origin,
      triger_time: new Date(),
      ua: window.navigator?.userAgent?.toLowerCase() || '',
      page: this.pageInfo.location,
      page_trace_id: this.pageInfo.uuid,
      env: ENV_KEY,
      page_state: JSON.stringify(this.pageInfo.state) || ''
    })
    this.buffers.push(data)
    this.debounceFn()
  }
  _bachPushFn() {
    // 截断数组
    let beforeSize = this.buffers.length
    let _temp = clone(this.buffers.splice(0, Math.min(MAX_BUFFER_SIZE, beforeSize)))
    this._log('截断后', beforeSize, this.buffers.length)
    // 后续需要做出断点续传
    this.buffers = []
    this._log('push msgs', _temp)
    this.batchPushMsg(_temp)
    this.lastUpTime = Date.now()
  }
  batchPushMsg(msgs = []) {
    this.$http.post(
      API_LOG,
      { list: msgs },
      {
        withCredentials: false
      }
    )
  }
  // 主动上报异常接口, 这里不依赖远程接口cache==true,而是直接上报
  upError(message = '') {
    if (DONT_MLOG) {
      return
    }
    try {
      this._pushMsg({
        desc: JSON.stringify(message),
        type: LOG_TYPES.error
      })
    } catch (err) {
      console.error(err)
    }
  }
  destory() {
    this._log('mconsole destory')
    this.config.cache = false
    this.config.needUpload = false
    if (this._oriConsole) {
      window.console.log = this._oriConsole
    }
    if (this._oriConsoleErr) {
      window.console.error = this._oriConsoleErr
    }
  }
  _log(...args) {
    if (this.config.debug) {
      console.info(args)
    }
  }
  _filterArgs(args = []) {
    // 针对错误信息 error 特殊处理
    // for (let i = 0, len = args.length; i < len; i++) {
    //     if ((typeof (args[i]) === 'object' && args[i].type === 'error')) {
    //         console.info('-----------error',args[i])
    //         this._log(args[i])
    //         args[i] = "[message]" + args[i].message + "[filename]" + (args[i].filename || 'null') + "[lineno]" + (args[i].lineno || 'null')
    //     }
    // }
    // 性能or 安全问题, 只搜集 log里面 类型为字符串或者数字类型的变量
    let filterArgs = args.filter((arg) => {
      let _type = typeof arg
      // this._log('00000000..000', arg, _type, arg.message, arg.type, arg.error?.type)
      return _type === 'string' || _type === 'number' || _type === 'boolean'
    })
    return filterArgs
  }
  // 上传短数据字符串时用的是c1
  upYueYing(msg, params, code, w_succ = 0, bizPrefix) {
    let msgUpload = this.getMsgAppendGroup(msg, bizPrefix)
    window.__wpk &&
      window.__wpk.report({
        category: code,
        msg: msgUpload,
        w_succ: w_succ,
        c1: typeof params === 'object' ? JSON.stringify(appendExtInfo(params)) : params // 可选， 辅助字段，可在日志详情中看到，共有c1~c5 5 个
      })
  }

  // 平均时间
  upYueYingAvg(msg, avg, code, bizPrefix) {
    let msgUpload = this.getMsgAppendGroup(msg, bizPrefix)
    window.__wpk &&
      window.__wpk.report({
        category: code,
        msg: msgUpload,
        wl_avgv1: avg,
        c1: sessionStorage.getItem('WEB_SESSION_ID')
      })
  }
  // 上传大数据字符串字段时用的是bl1
  upYueYing2(msg, params, code, w_succ = 0, bizPrefix) {
    let msgUpload = this.getMsgAppendGroup(msg, bizPrefix)
    window.__wpk &&
      window.__wpk.report({
        category: code,
        msg: msgUpload,
        w_succ: w_succ,
        bl1: typeof params === 'object' ? JSON.stringify(appendExtInfo(params)) : params // 可选， 辅助字段，可在日志详情中看到，共有c1~c5 5 个
      })
    trace.log('yueyin', {
      category: code,
      msg: msgUpload,
      w_succ: w_succ,
      bl1: typeof params === 'object' ? JSON.stringify(appendExtInfo(params)) : params // 可选， 辅助字段，可在日志详情中看到，共有c1~c5 5 个
    })
    console.log(typeof params === 'object' ? JSON.stringify(appendExtInfo(params)) : params)
  }
  startUploadByPage(page_key) {
    localStorage.setItem(page_key, new Date().getTime())
  }

  // 各业务线岳鹰的msg附加上版本前缀;(bizPrefix可以是数组,如果是数组,会用-连起来作为后缀)
  getMsgAppendGroup(msg, bizPrefix) {
    let msgUpload = msg || ''
    let zt_verName =
      (window.zzzconfig && window.zzzconfig.getZatuVerName && window.zzzconfig.getZatuVerName()) || 'zaitu-ver'

    const ztVer = (window.$tool && window.$tool.getCookie(zt_verName)) || ''
    if (typeof bizPrefix !== 'undefined' && ztVer != '') {
      if (Object.prototype.toString.call(bizPrefix) === '[object Array]') {
        bizPrefix.forEach((element) => {
          msgUpload = msgUpload + '_' + this.matchString(ztVer, element)
        })
      } else if (Object.prototype.toString.call(bizPrefix) === '[object String]') {
        msgUpload = msgUpload + '_' + this.matchString(ztVer, bizPrefix)
      }
    }
    return msgUpload
  }

  //   const input = "pp71p1-op37-de2-mo4-bi29-it36-ht32p2-bp62-ft55-et23p1-oa38p1-ex32-ca42-tr40";
  //   console.log(matchString(input, 'op')); // 输出: op37
  //   console.log(matchString(input, 'tr')); // 输出: tr40
  //   console.log(matchString(input, 'pp')); // 输出: pp71p1
  //   console.log(matchString(input, 'ht')); // 输出: ht32p2
  matchString(ztVerStr, searchString) {
    const groups = ztVerStr.split('-')
    const regex = new RegExp(`^${searchString}\\d+.*$`)
    for (const group of groups) {
      if (group.match(regex)) {
        return group
      }
    }
    return ''
  }

  /**
   *
   * @param {*} page_key 存储到localstorage的唯一key { w_succ: 1, category: 101, msg: '页面耗时', bl1: '', c1: '' }  c1只支持200个字符左右的小字符串, bl1:支持比较长的字符串
   * @param {*} config  { w_succ:是否成功(可以理解为接口是否成功) 0成功,1失败 ,category: 岳鹰监控分配的分类id,msg: 监控描述,如果首页加载耗时  }
   * maxTimeLimit 最大时长,多余这个时长会被丢弃;当成无效数据
   *
   * @returns
   */
  endUploadByPage(
    page_key,
    config = {},
    matchUrl,
    maxTimeLimit = 20000,
    bizPrefix // 各业务线前缀如 pp71p1-op37-de2-mo4-bi29-it36-ht32p2-bp62-ft55-et23p1-oa38p1-ex32-ca42-tr40 如 公共组pp,
  ) {
    let lTime = localStorage.getItem(page_key)
    if (!lTime) return
    if (matchUrl && location.href.indexOf(matchUrl) === -1) {
      console.log(page_key, '不匹配当前路由,抛弃', matchUrl, location.href)
      return
    }
    const lastTime = new Date().getTime() - parseInt(lTime)
    if (typeof maxTimeLimit !== 'number') {
      // 有的乱传参,导致maxTimeLimit错误
      maxTimeLimit = 10000
    }
    if (lastTime > maxTimeLimit) {
      // 脏数据,抛弃
      console.log('脏数据,抛弃', config.msg, lastTime)
      localStorage.removeItem(page_key)
      return
    }
    const msgUpload = this.getMsgAppendGroup(config.msg, bizPrefix)
    console.log('msgUpload', msgUpload, lastTime)
    const reportConfig = {
      category: config.category,
      msg: msgUpload,
      w_succ: config.w_succ,
      wl_avgv1: lastTime // 耗时100ms
    }
    trace.tag('endUploadByPage', {
      data: reportConfig
    })
    // 如果传了 bl1 ,则支持大字符串
    if (config.bl1 != '') {
      reportConfig.bl1 = config.bl1
    }
    if (config.c1 != '') {
      reportConfig.c1 = config.c1
    }
    window.__wpk && window.__wpk.report(reportConfig)
    // console.log(page_key + "上传成功", JSON.stringify(reportConfig))
    localStorage.removeItem(page_key)
  }
}
// 使用单利模式
let _instance = null
function getMLogInstance({ appProject }) {
  console.log('getMLogInstance')
  if (_instance) {
    return _instance
  }
  _instance = new mconsole({ appProject })
  window.mconsole = _instance
  return _instance
}
export default getMLogInstance
